
/*
* SLIDE OUT
*/

.slide-out-fwd-center {
	-webkit-animation: slide-out-fwd-center 0.7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-center 0.7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-fwd-top {
	-webkit-animation: slide-out-fwd-top 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-top 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-fwd-tr {
	-webkit-animation: slide-out-fwd-tr 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-tr 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-fwd-right {
	-webkit-animation: slide-out-fwd-right 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-right 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-fwd-br {
	-webkit-animation: slide-out-fwd-br 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-br 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-fwd-bottom {
	-webkit-animation: slide-out-fwd-bottom 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-bottom 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-fwd-bl {
	-webkit-animation: slide-out-fwd-bl 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-bl 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-fwd-left {
	-webkit-animation: slide-out-fwd-left 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-left 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-fwd-tl {
	-webkit-animation: slide-out-fwd-tl 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-fwd-tl 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:14:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-center
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-center {
  0% {
    -webkit-transform: translateZ(1);
            transform: translateZ(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-center {
  0% {
    -webkit-transform: translateZ(1);
            transform: translateZ(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:16:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-top {
  0% {
    -webkit-transform: translateZ(1) translateY(0);
            transform: translateZ(1) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px);
            transform: translateZ(600px) translateY(-300px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-top {
  0% {
    -webkit-transform: translateZ(1) translateY(0);
            transform: translateZ(1) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px);
            transform: translateZ(600px) translateY(-300px);
    opacity: 0;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:16:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-tr
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(400px);
            transform: translateZ(600px) translateY(-300px) translateX(400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(400px);
            transform: translateZ(600px) translateY(-300px) translateX(400px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:17:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateX(400px);
            transform: translateZ(600px) translateX(400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateX(400px);
            transform: translateZ(600px) translateX(400px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:20:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-br
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px) translateX(400px);
            transform: translateZ(600px) translateY(300px) translateX(400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px) translateX(400px);
            transform: translateZ(600px) translateY(300px) translateX(400px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:21:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px);
            transform: translateZ(600px) translateY(300px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px);
            transform: translateZ(600px) translateY(300px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:21:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-bl
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px) translateX(-400px);
            transform: translateZ(600px) translateY(300px) translateX(-400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px) translateX(-400px);
            transform: translateZ(600px) translateY(300px) translateX(-400px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:22:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateX(-400px);
            transform: translateZ(600px) translateX(-400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateX(-400px);
            transform: translateZ(600px) translateX(-400px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 21:22:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-fwd-tl
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-fwd-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(-400px);
            transform: translateZ(600px) translateY(-300px) translateX(-400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(-400px);
            transform: translateZ(600px) translateY(-300px) translateX(-400px);
    opacity: 0;
  }
}


/*
* FADE
*/
.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-fwd {
	-webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-bck {
	-webkit-animation: fade-in-bck 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bck 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-top {
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-tr {
	-webkit-animation: fade-in-tr 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-tr 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-right {
	-webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-br {
	-webkit-animation: fade-in-br 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-br 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-bl {
	-webkit-animation: fade-in-bl 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bl 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-left {
	-webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-tl {
	-webkit-animation: fade-in-tl 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-tl 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-bck
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
            transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
            transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-tr
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-tr {
  0% {
    -webkit-transform: translateX(50px) translateY(-50px);
            transform: translateX(50px) translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-tr {
  0% {
    -webkit-transform: translateX(50px) translateY(-50px);
            transform: translateX(50px) translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-br
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-br {
  0% {
    -webkit-transform: translateX(50px) translateY(50px);
            transform: translateX(50px) translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-br {
  0% {
    -webkit-transform: translateX(50px) translateY(50px);
            transform: translateX(50px) translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-bl
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-bl {
  0% {
    -webkit-transform: translateX(-50px) translateY(50px);
            transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bl {
  0% {
    -webkit-transform: translateX(-50px) translateY(50px);
            transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-tl
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
            transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
            transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * FADE OUT
 * ----------------------------------------
 */
.fade-out {
	-webkit-animation: fade-out 1s ease-out both;
	        animation: fade-out 1s ease-out both;
}

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
 @-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-4-13 20:20:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

// .slide-in-top {
// 	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }

// .slide-in-tr {
// 	-webkit-animation: slide-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }

// .slide-in-right {
// 	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }

// .slide-in-br {
// 	-webkit-animation: slide-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }

// .slide-in-bottom {
// 	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }

// .slide-in-bl {
// 	-webkit-animation: slide-in-bl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-in-bl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }

// .slide-in-left {
// 	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }

// .slide-in-tl {
// 	-webkit-animation: slide-in-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-in-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }

// /**
//  * ----------------------------------------
//  * animation slide-in-top
//  * ----------------------------------------
//  */
//  @-webkit-keyframes slide-in-top {
//   0% {
//     -webkit-transform: translateY(-1000px);
//             transform: translateY(-1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0);
//             transform: translateY(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-in-top {
//   0% {
//     -webkit-transform: translateY(-1000px);
//             transform: translateY(-1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0);
//             transform: translateY(0);
//     opacity: 1;
//   }
// }

// /**
//  * ----------------------------------------
//  * animation slide-in-tr
//  * ----------------------------------------
//  */
//  @-webkit-keyframes slide-in-tr {
//   0% {
//     -webkit-transform: translateY(-1000px) translateX(1000px);
//             transform: translateY(-1000px) translateX(1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0) translateX(0);
//             transform: translateY(0) translateX(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-in-tr {
//   0% {
//     -webkit-transform: translateY(-1000px) translateX(1000px);
//             transform: translateY(-1000px) translateX(1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0) translateX(0);
//             transform: translateY(0) translateX(0);
//     opacity: 1;
//   }
// }

// /**
//  * ----------------------------------------
//  * animation slide-in-right
//  * ----------------------------------------
//  */
//  @-webkit-keyframes slide-in-right {
//   0% {
//     -webkit-transform: translateX(1000px);
//             transform: translateX(1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateX(0);
//             transform: translateX(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-in-right {
//   0% {
//     -webkit-transform: translateX(1000px);
//             transform: translateX(1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateX(0);
//             transform: translateX(0);
//     opacity: 1;
//   }
// }

// /**
//  * ----------------------------------------
//  * animation slide-in-br
//  * ----------------------------------------
//  */
//  @-webkit-keyframes slide-in-br {
//   0% {
//     -webkit-transform: translateY(1000px) translateX(1000px);
//             transform: translateY(1000px) translateX(1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0) translateX(0);
//             transform: translateY(0) translateX(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-in-br {
//   0% {
//     -webkit-transform: translateY(1000px) translateX(1000px);
//             transform: translateY(1000px) translateX(1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0) translateX(0);
//             transform: translateY(0) translateX(0);
//     opacity: 1;
//   }
// }


// /**
//  * ----------------------------------------
//  * animation slide-in-bottom
//  * ----------------------------------------
//  */
//  @-webkit-keyframes slide-in-bottom {
//   0% {
//     -webkit-transform: translateY(1000px);
//             transform: translateY(1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0);
//             transform: translateY(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-in-bottom {
//   0% {
//     -webkit-transform: translateY(1000px);
//             transform: translateY(1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0);
//             transform: translateY(0);
//     opacity: 1;
//   }
// }

// /**
//  * ----------------------------------------
//  * animation slide-in-bl
//  * ----------------------------------------
//  */
//  @-webkit-keyframes slide-in-bl {
//   0% {
//     -webkit-transform: translateY(1000px) translateX(-1000px);
//             transform: translateY(1000px) translateX(-1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0) translateX(0);
//             transform: translateY(0) translateX(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-in-bl {
//   0% {
//     -webkit-transform: translateY(1000px) translateX(-1000px);
//             transform: translateY(1000px) translateX(-1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0) translateX(0);
//             transform: translateY(0) translateX(0);
//     opacity: 1;
//   }
// }

// /**
//  * ----------------------------------------
//  * animation slide-in-left
//  * ----------------------------------------
//  */
//  @-webkit-keyframes slide-in-left {
//   0% {
//     -webkit-transform: translateX(-1000px);
//             transform: translateX(-1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateX(0);
//             transform: translateX(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-in-left {
//   0% {
//     -webkit-transform: translateX(-1000px);
//             transform: translateX(-1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateX(0);
//             transform: translateX(0);
//     opacity: 1;
//   }
// }

// /**
//  * ----------------------------------------
//  * animation slide-in-tl
//  * ----------------------------------------
//  */
//  @-webkit-keyframes slide-in-tl {
//   0% {
//     -webkit-transform: translateY(-1000px) translateX(-1000px);
//             transform: translateY(-1000px) translateX(-1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0) translateX(0);
//             transform: translateY(0) translateX(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-in-tl {
//   0% {
//     -webkit-transform: translateY(-1000px) translateX(-1000px);
//             transform: translateY(-1000px) translateX(-1000px);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateY(0) translateX(0);
//             transform: translateY(0) translateX(0);
//     opacity: 1;
//   }
// }


.slide-in-fwd-center {
	-webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-top {
	-webkit-animation: slide-in-fwd-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-tr {
	-webkit-animation: slide-in-fwd-tr 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-tr 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-right {
	-webkit-animation: slide-in-fwd-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-br {
	-webkit-animation: slide-in-fwd-br 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-br 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-bottom {
	-webkit-animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-bl {
	-webkit-animation: slide-in-fwd-bl 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-bl 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-left {
	-webkit-animation: slide-in-fwd-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-tl {
	-webkit-animation: slide-in-fwd-tl 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-tl 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-top {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px);
            transform: translateZ(-1400px) translateY(-800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-top {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px);
            transform: translateZ(-1400px) translateY(-800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-tr
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
            transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
            transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
            transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
            transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-br
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-br {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(1000px);
            transform: translateZ(-1400px) translateY(800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-br {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(1000px);
            transform: translateZ(-1400px) translateY(800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px);
            transform: translateZ(-1400px) translateY(800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px);
            transform: translateZ(-1400px) translateY(800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-bl
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-bl {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(-1000px);
            transform: translateZ(-1400px) translateY(800px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-bl {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(-1000px);
            transform: translateZ(-1400px) translateY(800px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * animation slide-in-fwd-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
            transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
            transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-tl
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-tl {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(-1000px);
            transform: translateZ(-1400px) translateY(-800px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-tl {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(-1000px);
            transform: translateZ(-1400px) translateY(-800px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
