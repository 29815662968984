@import "../../css/mixins";
.work-content {
  display: flex;
  img {
    max-width: 100%;
    height: auto;
  }
  h3 {
    font-size: 1.5rem;
  }

  .button {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border: #fff 1px solid;
    border-radius: 20px;
    text-transform: uppercase;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
    .svg-inline--fa {
      margin-right: 10px;
    }
    &:hover {
      background: #fff;
      color: #000;
    }
    + .button {
      margin-left: 20px;
    }
  }

  &-left {
    flex: 1;
  }
  &-right {
    flex: 2;
  }

  @include respond-below(sm) {
    flex-direction: column-reverse;
    justify-content: center;
    overflow: hidden;
  }
  
}