@import "../../css/animations";
@import "../../css/gradients";
@import "../../css/mixins";

.activepage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  z-index: 0;
  opacity: 0;
  transition: all 0.5s ease;
  animation-delay: 2s!important;
  @include respond-below(sm) {
    padding: 30px;
  }
  &.is-inactive {
    z-index: 0;
    @extend .fade-out;
    animation-delay: 1s!important;
    opacity: 1;
  }

  &.is-active {
    z-index: 999;
    @extend .fade-in;
    // opacity: 1;

    &.pg-logo {
      @extend .fade-in;
    }

    /* Top Left */
    &.pg-0, &.pg-1 {
      @extend .fade-in-br;
    }

    /* Top */
    &.pg-2 {
      @extend .fade-in-bottom;
    }

    /* Top Right */
    &.pg-3, &.pg-4 {
      @extend .fade-in-bl;
    }

    /* Left */
    &.pg-5, &.pg-9,
    &.pg-6, &.pg-10 {
      @extend .fade-in-right;
    }

     /* Right */
     &.pg-7, &.pg-8,
     &.pg-12, &.pg-13 {
       @extend .fade-in-left;
     }

    /* Bottom */
    &.pg-11, &.pg-16 {
      @extend .fade-in-top;
    }

    /* Bottom Left */
    &.pg-14, &.pg-15 {
      @extend .fade-in-tr;
    }

    /* Bottom Right */
    &.pg-17, &.pg-18 {
      @extend .fade-in-tl;
    }

  }

}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

// @for $i from 0 through 18 {
//   .pg-#{$i} {
//     animation-delay: calc((calc(#{randomNum(1, 18)}s / 100)) * #{$i})!important;
//   }
// }

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor: pointer;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
