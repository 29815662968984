.link-hover-effect {
  position: relative;
  display: inline-block;
    &::before {
      transition: all 0.5s ease;
      background: rgba(#fff, .4);
      content: "";
      display: block;
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
}