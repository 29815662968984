@import "../../../css/mixins";
@import "../../../css/animations";

.section-works {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .button-back {
    position: absolute;
    z-index: 999;
    display: none;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    font-size: 2rem;
    color: rgba(#fff, .7);
    width: 70px;
    height: 70px;
    transition: all 0.5s ease;

    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);

    .arrow-up {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }

    .progress {
      position: absolute;
      width: 50px;
      height: 50px;
      top: 10px;
      left: 10px;
      transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    }

    &:focus-visible .progress__circle {
      fill: rgba(#000,0.4);
    }

    &:hover {
      color: rgba(#fff, 1);
      
      .progress {
        transform: scale3d(1.2, 1.2, 1);
      }

      .progress__path {
        stroke-dashoffset: 0;
      }
    }
  }

.progress__circle,
.progress__path {
	fill: none;
	stroke: rgba(#fff, .5);
	stroke-width: 2px;
}

  .progress__path {
    stroke: #fff;
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  }

  &-main {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;

    @include respond-below(sm) {
      flex-direction: column;
      height: 100vh;
      justify-content: center;
      overflow: hidden;

    }
  }

  .work-active {
    width: 100%;
    height: 0;
    opacity: 1;
    @extend .slide-in-fwd-bottom;
    
  }

  &-active {
    .section-works-main {
      @extend .slide-out-fwd-top;
      height: 0;
      overflow: hidden;
    }
    .work-active {
      height: 90vh;
      padding-top: 10px;
      @include respond-below(sm) {
        overflow-y: auto;
      }
    }

     .button-back {
       display: block;
     }
  }

  @include respond-below(sm) {
    flex-direction: column;
    height: 100vh;
    justify-content: center;

  }

  header, &-navigation {
    flex: 1;
    width: 50%;

    @include respond-below(sm) {
      width: 100%;
      flex: none;
    }
  }
  &-navigation {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        // background: rgba(#fff, .5);
        
        font-size: 5em;
        line-height: 80%;
        transition: all 0.5s ease;
        &:hover {
          font-size: 8em;
        }

        @include respond-below(sm) {
          font-size: 3em;
          &:hover {
            font-size: 5em;
          }
        }
      }
    }
    a {
      color: rgba(#fff, .5);
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
      &:hover {
        color: #fff;
      }

    }
  }

}

.work-active {
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
}