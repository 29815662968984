@import "../../css/animations";
@import "../../css/mixins";

.pagebox-wrapper {
  width: 20%;
  height: calc(100vh / 4);
  perspective: 500px;
  z-index: 100;
  
  .pagebox {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    text-align: center;
    transition: all 0.5s ease;
    @include respond-below(sm) {
      min-height: 40px;
    }
    &.text-dark {
      color: #000;
    }
    &.logobox,
    &.has-content {
      cursor: pointer;
      &:hover {
        border: 10px solid rgba(#fff, .6);
        @include respond-below(sm) {
          border: none;
        }
      }
      @include respond-below(sm) {
        &::before {
          transition: all 0.5s ease;
          background: rgba(#fff, .3);
          content: "";
          display: block;
          width: 100%;
          height: 0;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        &:hover {
          &::before {
            height: 100%;
          }
        }
      }
    }

    &.logobox {
      font-size: 3.5rem;
      line-height: 90%;
      padding: 0 30px;
      @include respond-below(sm) {
        height: calc(100vh / 6);
      }
    }

    &.has-content {
      @include respond-below(sm) {
        height: calc(100vh / 6);
      }
    }

  }

  &.pw-active {
    
  }

  @include respond-below(sm) {
    height: auto;
    width: 100%;
  }
}

.wrapper-default {
  .activepage {
    @extend .fade-out;
    animation-delay: 0!important;
  }
  .pagebox-wrapper {
    .pagebox {
      &.logobox {
        @extend .slide-in-fwd-center;
        
      }

      /* Top Left */
      &.pg-0, &.pg-1 {
        @extend .slide-in-fwd-tl;
      }

      /* Top */
      &.pg-2 {
        @extend .slide-in-fwd-top;
      }

      /* Top Right */
      &.pg-3, &.pg-4 {
        @extend .slide-in-fwd-tr;
      }

      /* Left */
      &.pg-5, &.pg-9,
      &.pg-6, &.pg-10 {
        @extend .slide-in-fwd-left;
      }

       /* Right */
       &.pg-7, &.pg-8,
       &.pg-12, &.pg-13 {
         @extend .slide-in-fwd-right;
       }

      /* Bottom */
      &.pg-11, &.pg-16 {
        @extend .slide-in-fwd-bottom;
      }

      /* Bottom Left */
      &.pg-14, &.pg-15 {
        @extend .slide-in-fwd-bl;
      }

      /* Bottom Right */
      &.pg-17, &.pg-18 {
        @extend .slide-in-fwd-br;
      }
    
    }
  }
}


.wrapper-active {
  .pagebox-wrapper {
    .pagebox {
      z-index: 999;
    
      &.logobox {
        @extend .slide-out-fwd-center;
        
      }

      /* Top Left */
      &.pg-0, &.pg-1 {
        @extend .slide-out-fwd-tl;
      }

      /* Top */
      &.pg-2 {
        @extend .slide-out-fwd-top;
      }

      /* Top Right */
      &.pg-3, &.pg-4 {
        @extend .slide-out-fwd-tr;
      }

      /* Left */
      &.pg-5, &.pg-9,
      &.pg-6, &.pg-10 {
        @extend .slide-out-fwd-left;
      }

       /* Right */
       &.pg-7, &.pg-8,
       &.pg-12, &.pg-13 {
         @extend .slide-out-fwd-right;
       }

      /* Bottom */
      &.pg-11, &.pg-16 {
        @extend .slide-out-fwd-bottom;
      }

      /* Bottom Left */
      &.pg-14, &.pg-15 {
        @extend .slide-out-fwd-bl;
      }

      /* Bottom Right */
      &.pg-17, &.pg-18 {
        @extend .slide-out-fwd-br;
      }


      @include respond-below(sm) {
        &.logobox,
        &.pg-0, &.pg-1, &.pg-2, &.pg-3, &.pg-4, &.pg-5, &.pg-6, &.pg-7, &.pg-8,
        &.pg-9, &.pg-10, &.pg-11, &.pg-12, &.pg-13, &.pg-14, &.pg-15, &.pg-16, &.pg-17, &.pg-18{ 
          
        }
        
      }
    
    }
  }
}

