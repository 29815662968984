.red-gradient {
  background-image: linear-gradient(to right top, #051937, #40255b, #852269, #c71c5c, #f44336);
}

.pink-gradient {
  background-image: linear-gradient(to right top, #051937, #35265a, #712970, #b02073, #e91e63);
}

.purple-gradient {
  background-image: linear-gradient(to right top, #051937, #17255b, #3b2e7d, #68309a, #9c27b0);
}

.deeppurple-gradient {
  background-image: linear-gradient(to right top, #051937, #0a2558, #212f79, #413699, #673ab7);
}

.indigo-gradient {
  background-image: linear-gradient(to right top, #051937, #0a2755, #173574, #294394, #3f51b5);
}

.blue-gradient {
  background-image: linear-gradient(to right top, #051937, #123461, #1d528f, #2473c0, #2196f3);
}

.lightblue-gradient {
  background-image: linear-gradient(to right top, #051937, #133862, #1d5b91, #1d81c2, #03a9f4);
}

.cyan-gradient {
  background-image: linear-gradient(to right top, #051937, #053c61, #00648b, #008fb2, #00bcd4);
}

.teal-gradient {
  background-image: linear-gradient(to right top, #051937, #003657, #005571, #007681, #009688);
}

.green-gradient {
  background-image: linear-gradient(to right top, #051937, #003e63, #006579, #008c70, #4caf50);
}

.lightgreen-gradient {
  background-image: linear-gradient(to right top, #051937, #004468, #00727d, #009e6e, #8bc34a);
}

.lime-gradient {
  background-image: linear-gradient(to right top, #051937, #004c71, #008284, #17b667, #cddc39);
}

.yellow-gradient {
  background-image: linear-gradient(to right top, #051937, #663366, #c94e6a, #ff8f4c, #ffeb3b);
}

.amber-gradient {
  background-image: linear-gradient(to right top, #051937, #5d2e63, #b93e68, #f87048, #ffc107);
}

.orange-gradient {
  background-image: linear-gradient(to right top, #051937, #532a60, #a83267, #ea534c, #ff9800);
}

.deeporange-gradient {
  background-image: linear-gradient(to right top, #051937, #45265e, #92216a, #d72156, #ff5722);
}

.yellow-gradient {
  background-image: linear-gradient(to right top, #051937, #663366, #c94e6a, #ff8f4c, #ffeb3b);
}

.brown-gradient {
  background-image: linear-gradient(to right top, #051937, #34223f, #532e42, #6a3f44, #795548);
}

.grey-gradient {
  background-image: linear-gradient(to right top, #051937, #30364f, #555668, #797982, #9e9e9e);
}

.bluegrey-gradient {
  background-image: linear-gradient(to right top, #051937, #18304c, #2d4961, #456376, #607d8b);
}
