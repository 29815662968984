@import "../../../css/mixins";
.section {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 0;
  
  &-about {
    font-size: 2rem;
    .hello {
      margin: 10px 0 30px;
    }
    .name {
      font-size: 4rem;
      margin: 0 0 20px;

      @include respond-below(sm) {
        font-size: 2.5rem;
      }
    }
  }
}