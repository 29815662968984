@import "../../css/mixins";
html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 16px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
  overflow: hidden;
  position: relative;

  @include respond-below(sm) {
    flex-direction: column;
    overflow: auto;
    flex-wrap: nowrap;

    &.isloading {
      flex-wrap: wrap;
    }

    &-active {
      .pagebox-wrapper {
        display: none;
      }
    }
  }

}

