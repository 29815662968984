@import "../../../css/global";
@import "../../../css/mixins";

.section-contact {
  p {
    font-size: 2rem;
  }
  a {
    color: #fff;
    text-decoration: none;
    border-bottom: #fff 2px solid;
    @extend .link-hover-effect;
  }
  
  .content {
    z-index: 9;
    position: relative;
  }
  #tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
     @include respond-below(sm) {
      height: 100%;
    }
  }
}